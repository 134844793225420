import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Container from "@material-ui/core/Container";
import Typography from "@material-ui/core/Typography";

import Navbar from "../components/Navbar";

const useStyles = makeStyles((theme) => ({
mainContainer: {
  color: theme.color,
  height: "100vh",
  width: "100vw",
  backgroundColor: "#006591"
},
heading: {
  // color: "tomato",
  padding: "3rem 0",
  textTransform: "uppercase",
  [theme.breakpoints.down("md")]: {
    fontSize: "20px"
  }
}
}))


const Blueprint = () => {
  const classes = useStyles();
  return (
    <div className={classes.mainContainer}>
      <Container>
        <embed src={"/sd.svg"} />
      </Container>
    </div>
  );
};

export default Blueprint;