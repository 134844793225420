import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import BottomNavigation from "@material-ui/core/BottomNavigation";
import BottomNavigationAction from "@material-ui/core/BottomNavigationAction";
import Instagram from "@material-ui/icons/Instagram";

const useStyles = makeStyles((theme) => ({
  bottomNavContainer: {
    background: "#222",
  },
  root: {
    "& .MuiSvgIcon-root": {
      fill: "#a5a5a5", 
      "&:hover": {
        fontSize: "1.8rem",
      },
    },
  },
  developerIcon: {
    color: "#a5a5a5",
    fontSize: "1.5rem",
    "&:hover": {
      fontSize: "1.8rem",
    }
  }
}));

const Footer = () => {
  const classes = useStyles();

  return (
    <BottomNavigation className={classes.bottomNavContainer}>
      <BottomNavigationAction icon={<i className={"devicon-github-original-wordmark " + classes.developerIcon}></i>
} className={classes.root} />
      <BottomNavigationAction icon={<i className={"devicon-linkedin-plain " + classes.developerIcon}></i>} className={classes.root} />
      <BottomNavigationAction icon={<Instagram />} className={classes.root} />
    </BottomNavigation>
  );
};
export default Footer;