import "./wallpaper.css"
import React, {useEffect} from "react";
import Avatar from "@material-ui/core/Avatar";
import { Link } from "react-router-dom";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import Typed from "react-typed";
import { makeStyles } from "@material-ui/core/styles";
import Button from '@material-ui/core/Button';
import PreviewIcon from '@material-ui/icons/Pageview';
import avatar from "../../avatar.jpg";

const useStyles = makeStyles((theme) => ({
  avatar: {
    width: theme.spacing(15),
    height: theme.spacing(15),
    margin: theme.spacing(1),
  },
  title: {
    color: "tomato",
    marginBottom: "10px"
  },
  subtitle: {
    color: "tan",
    textTransform: "uppercase",
    marginBottom: "10px"
  },
  typedContainer: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%,-50%)",
    width: "100vw",
    textAlign: "center",
    zIndex: 1,
  },
  viewMore: {
    textDecoration: "none", 
  },
  viewMoreButton: {borderColor: "#fff", borderSize: "2px", color: "#fff"}
}));

export default function Wallpaper(props) {
  const classes = useStyles();

  useEffect(() => {
    return () => {
    };
  },
  []);
  return(<div className={'main-wallpaper'}>
    <div className={"landscape"}></div>
    <div className={"filter"}></div>
    <div className={"bluePrint"}>
      <div style={{textAlign: "center"}}>
      </div>
    </div>
    <div className={"profile"}>
      <Box className={classes.typedContainer}>
        <Grid container justify="center">
          <Avatar className={classes.avatar} src={avatar} alt={process.env.REACT_APP_NAME} />
        </Grid>
        <Typography className={classes.title} variant="h4">
          Hi I'm {process.env.REACT_APP_NAME}
        </Typography>

        <Typography className={classes.subtitle} variant="h5">
          <Typed
            strings={[
              "Senior Software Engineer",
              "Frontend Developer",
              "Backend Developer",
              "Devops Engineer"
            ]}
            typeSpeed={100}
          />
        </Typography>
        <Link to="/resume" className={classes.viewMore}>
          <Button variant="outlined" className={classes.viewMoreButton}><Typed strings={["Know more About me"]} /></Button>
        </Link>
      </Box>
    </div>
  </div>)
}