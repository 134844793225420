import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Container from "@material-ui/core/Container";
import Box from "@material-ui/core/Box";
import Navbar from "../components/Navbar";

const useStyles = makeStyles((theme) => ({
  mainContainer: {
    ...theme.mainContainer
  },
  timeLine: {
    position: "relative",
    padding: "1rem",
    margin: "o auto",
    "&:before": {
      content: "''",
      position: "absolute",
      height: "100%",
      border: "1px solid tan",
      right: "40px",
      top: 0,
    },
    "&:after": {
      content: "''",
      display: "table",
      clear: "both",
    },
    [theme.breakpoints.up("md")]: {
      padding: "2rem",
      "&:before": {
        left: "calc(50% - 1px)",
        right: "auto",
      },
    },
  },
  timeLineItem: {
    padding: "1rem",
    borderBottom: "2px solid tan",
    position: "relative",
    margin: "1rem 3rem 1rem 1rem",
    clear: "both",
    "&:after": {
      content: "''",
      position: "absolute",
    },
    "&:before": {
      content: "''",
      position: "absolute",
      right: "-0.625rem",
      top: "calc(50% - 5px)",
      borderStyle: "solid",
      borderColor: "tomato tomato transparent transparent",
      borderWidth: "0.625rem",
      transform: "rotate(45deg)",
    },
    [theme.breakpoints.up("md")]: {
      width: "44%",
      margin: "1rem",
      "&:nth-of-type(2n)": {
        float: "right",
        margin: "1rem",
        borderColor: "tan",
      },
      "&:nth-of-type(2n):before": {
        right: "auto",
        left: "-0.625rem",
        borderColor: "transparent transparent tomato tomato",
      },
    },
  },
  timeLineYear: {
    textAlign: "center",
    maxWidth: "9.375rem",
    margin: "0 3rem 0 auto",
    fontSize: "1rem",
    fontWeight: "bold",
    color: "#fff",
    background: "tomato",
    lineHeight: 1,
    padding: "0.5rem 1rem",
    "&:before": {
      display: "none",
    },
    [theme.breakpoints.up("md")]: {
      textAlign: "center",
      margin: "0 auto",
      "&:nth-of-type(2n)": {
        float: "none",
        margin: "0 auto",
      },
      "&:nth-of-type(2n):before": {
        display: "none",
      },
    },
    [theme.breakpoints.down("md")]: {
      fontSize: "1rem",
      fontWeight: "bold"
    }
  },
  heading: {
    // color: "tomato",
    padding: "3rem 0",
    textTransform: "uppercase",
    [theme.breakpoints.down("md")]: {
      fontSize: "20px"
    }
  },
  subHeading: {
    padding: 0,
    textTransform: "uppercase",
  },
  body1: {},
  subtitle1: {
    color: theme.palette.type === "dark" ? "tan" : "#a5a5a5"
  },
}));

const Resume = () => {
  const classes = useStyles();
  return (
    <>
      <Navbar />
      <Container component="div" className={classes.mainContainer}>
        
        <Typography variant="h4" align="center" className={classes.heading}>
          Working Experience
        </Typography>
        <Box component="div" className={classes.timeLine}>
          <Typography
            variant="h2"
            className={`${classes.timeLineYear} ${classes.timeLineItem}`}
          >
            2016
          </Typography>
          <Box component="div" className={classes.timeLineItem}>
            <Typography
              variant="h5"
              align="center"
              className={classes.subHeading}
            >
              Ruby on Rails Developer
            </Typography>
            <Typography variant="body1" align="center" className={classes.body1}>
              ForteLogic Pvt Ltd
            </Typography>
            <Typography
              variant="subtitle1"
              align="center"
              className={classes.subtitle1}
            >
              Lorem ipsum dolor sit amet consectetur adipisicing elit. Vero quas
              ipsa, laudantium totam perferendis possimus voluptatibus tenetur.
              Quasi voluptatibus, nam vitae eaque ad, officia laboriosam
              repudiandae, rerum necessitatibus nisi mollitia.
            </Typography>
          </Box>
          <Typography
            variant="h2"
            className={`${classes.timeLineYear} ${classes.timeLineItem}`}
          >
            2017
          </Typography>
          <Box component="div" className={classes.timeLineItem}>
            <Typography
              variant="h5"
              align="center"
              className={classes.subHeading}
            >
              Ruby on Rails Developer
            </Typography>
            <Typography variant="body1" align="center" className={classes.body1}>
              Finology Sdn Bhd
            </Typography>
            <Typography
              variant="subtitle1"
              align="center"
              className={classes.subtitle1}
            >
              Lorem ipsum dolor sit amet consectetur adipisicing elit. Vero quas
              ipsa, laudantium totam perferendis possimus voluptatibus tenetur.
              Quasi voluptatibus, nam vitae eaque ad, officia laboriosam
              repudiandae, rerum necessitatibus nisi mollitia.
            </Typography>
          </Box>
          <Typography
            variant="h2"
            className={`${classes.timeLineYear} ${classes.timeLineItem}`}
          >
            2019 - Present
          </Typography>
          <Box component="div" className={classes.timeLineItem}>
            <Typography
              variant="h5"
              align="center"
              className={classes.subHeading}
            >
              Senior Software Engineer
            </Typography>
            <Typography variant="body1" align="center" className={classes.body1}>
              Revmedia
            </Typography>
            <Typography
              variant="subtitle1"
              align="center"
              className={classes.subtitle1}
            >
              Lorem ipsum dolor sit amet consectetur adipisicing elit. Vero quas
              ipsa, laudantium totam perferendis possimus voluptatibus tenetur.
              Quasi voluptatibus, nam vitae eaque ad, officia laboriosam
              repudiandae, rerum necessitatibus nisi mollitia.
            </Typography>
          </Box>
        </Box>
      </Container>
      </>
  );
};

export default Resume;