   
import React from "react";
import Wallpaper from "../components/wallpaper";

const Home = () => {
  return (
    <div className={'home'}>
      <Wallpaper />
    </div>
  );
};

export default Home;