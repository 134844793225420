
import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Container from "@material-ui/core/Container";
import Typography from "@material-ui/core/Typography";

import Navbar from "../components/Navbar";

const useStyles = makeStyles((theme) => ({
mainContainer: {
  color: theme.color,
  height: "100%",
  ...theme.mainContainer
},
heading: {
  // color: "tomato",
  padding: "3rem 0",
  textTransform: "uppercase",
  [theme.breakpoints.down("md")]: {
    fontSize: "20px"
  }
},
skill: {
  // "& i": {
  //   fontSize: "60px"
  // }
}
}))

const Skill = (props) => {
  let { icon, size } = props
  return(
  <Grid item xs={4} md={1}>
    <i className={icon} style={{fontSize: size || "60px" }}></i>
  </Grid>)
}

export default function Skills() {
  const classes = useStyles();
  return (
    <>
    <Navbar />
    <Container component="div" className={classes.mainContainer}>
      <Typography variant="h4" align="center" className={classes.heading}>
        Skills
      </Typography>
      <Grid container direction="row" className={classes.skill}>
        <Grid item xs={12} >
          <Typography item variant="h5">
            Programming Languages
          </Typography>
        </Grid>
        <Skill icon={"devicon-ruby-plain"} size={"40px"} />
        <Skill icon={"devicon-python-plain"} size={"40px"}  />
        <Skill icon={"devicon-javascript-plain"} size={"40px"} />
        <Skill icon={"devicon-html5-plain-wordmark"} size={"40px"} />
        <Grid item xs={12} >
          <Typography item variant="h5">
            Backend
          </Typography>
        </Grid>
        <Skill icon={"devicon-rails-plain-wordmark"} />
        <Skill icon={"devicon-nodejs-plain-wordmark"} />
        <Skill icon={"devicon-django-plain"} />
        <Skill icon={"devicon-flask-original"} />
        <Grid item xs={12} >
          <Typography item variant="h5">
            Frontend
          </Typography>
        </Grid>
        <Skill icon={"devicon-react-original"} size={"40px"} />
        <Skill icon={"devicon-wordpress-plain"} size={"40px"} />

        <Grid item xs={12} >
          <Typography item variant="h5">
            Databases
          </Typography>
        </Grid>
        <Skill icon={"devicon-mysql-plain-wordmark"} />
        <Skill icon={"devicon-postgresql-plain-wordmark"} />
        <Skill icon={"devicon-redis-plain-wordmark"} />

        <Grid item xs={12} >
          <Typography item variant="h5">
            Devops
          </Typography>
        </Grid>
        <Skill icon={"devicon-nginx-original"} />

        
        <Skill icon={"devicon-googlecloud-plain-wordmark"} size={"80px"} />
        <Skill icon={"devicon-amazonwebservices-plain-wordmark"} size={"80px"}/>
      </Grid>
    </Container>
    </>
  );
}