import React from "react";
import { Route, Switch } from "react-router-dom";

import CssBaseline from "@material-ui/core/CssBaseline";
import { Home, Resume, Portfolio, Contact, Skills,
  BluePrint } from "./pages";
import { ThemeProvider, createTheme } from "@material-ui/core/styles";
import useMediaQuery from '@material-ui/core/useMediaQuery';

import './App.css';

export default function App() {

  const themeType = useMediaQuery('(prefers-color-scheme: dark)') ? "dark" : "light"
  const currentTheme = createTheme({
    palette: {
      type: themeType,
    },
    appbar: {
      dark: "#303030",
      main: "#ddd",
      light: "#ffffff"
    },
    mainContainer: {
      marginTop: "50px"
    }
  })

  return (
    <ThemeProvider theme={currentTheme}>
      <CssBaseline />
      <Switch>
        <Route exact path="/" component={Home} />
        <Route exact path="/resume" component={Resume} />
        <Route exact path="/portfolio" component={Portfolio} />
        <Route exact path="/contact" component={Contact} />
        <Route exact path="/skills" component={Skills} />
        <Route exact path="/blueprint" component={BluePrint} />
      </Switch>
    </ThemeProvider>
  );
}
